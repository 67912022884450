import { CommonModule } from '@angular/common';
import { Component, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { MatTabsModule } from '@angular/material/tabs';
import { RouterModule } from '@angular/router';
import {
  LIMIT_REPORT_FORM_FACADE,
  LimitReportStore,
} from '@backoffice-frontend/limit-report/domain';
import { LimitReportPermissions } from '@backoffice/shared/bo/util-permissions';
import { CanDeactivateTabPage } from '@clean-code/shared/components/ui-can-deactivate-tab-page';
import { PageContainerModule } from '@clean-code/shared/components/ui-page-container';
import { TranslocoModule } from '@jsverse/transloco';
import { NgxPermissionsModule } from 'ngx-permissions';
import { LimitReportFormFacade } from '../../services/limit-report-form.facade';

@Component({
  standalone: true,
  templateUrl: './limit-report-power-tab.component.html',
  styleUrls: ['./limit-report-power-tab.component.scss'],
  imports: [
    CommonModule,
    RouterModule,

    MatTabsModule,

    TranslocoModule,
    NgxPermissionsModule,

    PageContainerModule,
  ],
  providers: [
    { provide: LIMIT_REPORT_FORM_FACADE, useClass: LimitReportFormFacade },
    LimitReportStore,
  ],
})
export class LimitReportPowerTabComponent
  extends CanDeactivateTabPage
  implements OnInit, OnDestroy
{
  @HostBinding('class.page-component') pageComponentClass = true;

  protected override titleField = 'name';
  public permissions = LimitReportPermissions;

  constructor(facade: LimitReportFormFacade) {
    super(null, facade);
  }
}
