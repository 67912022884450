<cc-dashboard-page-container>
  <nav mat-tab-nav-bar [tabPanel]="tabPanel" class="print:!hidden">
    @for (link of navLinks$ | async; track link) {
      @if (!(link?.data?.disable && (isNew$ | async))) {
        <ng-template [ngxPermissionsOnly]="link.permissions">
          <a
            mat-tab-link
            #rla="routerLinkActive"
            [routerLink]="link.path"
            routerLinkActive
            [active]="rla.isActive"
            >
            {{ link.label | transloco }}
          </a>
        </ng-template>
      }
    }
  </nav>
  <mat-tab-nav-panel #tabPanel>
    <router-outlet></router-outlet>
  </mat-tab-nav-panel>
</cc-dashboard-page-container>
